import "./src/styles/style.scss"
import 'intersection-observer'

let injectedScript = false

export const onInitialClientRender = () => {
  
  var consoleH1Style =
    "font-size: 16px;" +
    "background: #5627d8;" +
    "color: white;" +
    "text-align: center;" +
    "padding: 10px 15px;" +
    "width: 100%;" +
    "border-radius: 10px;"

  var consoleTextStyle =
    "font-size: 12px;" +
    "background: white;" +
    "color: #5627d8;" +
    "text-align: center;" +
    "padding: 5px;" +
    "width: 100%;" +
    "border-radius: 5px;"

  console.groupCollapsed("%cHello there 👀!", consoleH1Style, "click here")

  console.log("%cI like how you think. Let's connect!", consoleTextStyle)
  console.log("%cEmail me at console@tnoor.dev", consoleTextStyle)
  console.log("%cor call/text me @ (727) 537-6866", consoleTextStyle)
  console.log("%cI look forward to hearing from you. - TJ", consoleTextStyle)
  console.groupEnd()

  if (!injectedScript) {
    var pwaScript = document.createElement(`script`)
    pwaScript.type = `text/javascript`
    pwaScript.src =
      "https://cdn.jsdelivr.net/npm/pwacompat@2.0.9/pwacompat.min.js"
    pwaScript.setAttribute(
      "integrity",
      "sha384-VcI6S+HIsE80FVM1jgbd6WDFhzKYA0PecD/LcIyMQpT4fMJdijBh0I7Iblaacawc"
    )
    pwaScript.setAttribute("crossorigin", "anonymous")
    document.getElementsByTagName(`head`)[0].appendChild(pwaScript)
  }

  // var yearFooter = document.getElementById(`copyrightYear`)
  // if (yearFooter !== null && typeof yearFooter !== "undefined") {
  //   yearFooter.innerText = new Date().getFullYear()
  // }
}

export const onClientEntry = () => {
  // if (typeof window.IntersectionObserver === `undefined`) {
  //   console.log('IntersectionObserver = undefined');
  // }

  if (typeof window !== "undefined") {
    var WebFont = require("webfontloader")
    WebFont.load({
      google: {
        families: [
          "Raleway:400,900|Crimson+Pro&display=swap",
          // "Open+Sans",
          // "Montserrat:900",
          // "Roboto:300,400,500,700",
          // "Darker+Grotesque",
        ],
      },
    })
  }
}

export const onRouteUpdate = () => {
  // var yearFooter = document.getElementById(`copyrightYear`)
  // if (yearFooter !== null && typeof yearFooter !== "undefined") {
  //   yearFooter.innerText = new Date().getFullYear()
  // }
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `tnoor.dev has been updated. Would you like to view the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}
